@import url('https://fonts.googleapis.com/css?family=Inter');

.landing-page {
  font-family: Inter;
  font-style: normal;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #333333;

  & > section {
    background-color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 0 calc((100% - 1200px) / 2);

    & .section-wrap {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 1200px;
      min-height: 150px;
      padding: 0;
      margin: 0;

      & img.icon {
        user-select: none;
        position: relative;
      }

      & > h3 {
        font-weight: bold;
        font-size: 48px;
        line-height: 58px;
        color: #333333;
        width: 55%;
        margin-bottom: 30px;
      }

      & > span {
        font-size: 20px;
        color: #858a8f;
        font-weight: 400;
        width: 55%;
      }
    }

    &.primary {
      background-color: #eafaf3;
    }

    &.partners-list {
      & > .section-wrap {
        display: flex;
        flex-direction: row;
        justify-content: center;

        & > .partner-card {
          display: flex;
          justify-content: center;
          flex: 1;

          & img {
            height: 50px;
          }
        }
      }
    }

    &.delivery-steps {
      & h3 {
        font-weight: bold;
        font-size: 48px;
        line-height: 58px;
        color: #333333;
        width: 55%;
        margin-bottom: 30px;
        text-align: center;
      }

      & > .section-wrap {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 50px;

        & > .step-card {
          display: flex;
          justify-content: center;
          vertical-align: middle;
          flex-direction: column;
          align-self: flex-start;
          margin: 16px;

          & .image {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 100px;
            margin-bottom: 80px;
            border-radius: 24px;
            background-color: #2dca8c;

            & img {
              aspect-ratio: 1;
              height: 50%;
            }
          }

          & span {
            font-size: 12px;
            color: #777e90;
          }

          & p {
            font-size: 14px;
            margin: auto -25px;
          }
        }

        & > .connect {
          width: 80px;
          align-self: flex-start;
          margin: auto 16px;
          margin-top: 66px;
        }
      }
    }

    &.intro-poster {
      background: linear-gradient(0deg, rgba(5, 5, 5, 0.6), rgba(5, 5, 5, 0.6)),
        url('../../style/assets/intro_bg.jpg');
      background-size: cover;
      background-position: center;
      padding-top: 20px;

      & > .section-wrap {
        color: #ffffff;
        text-align: left;
        align-items: flex-start;
      }

      & header {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 70px;
        width: 100%;
        justify-content: space-between;

        .pull-left {
          display: flex;
        }

        .logo {
          display: inline-flex;
          height: 40px;
          margin-right: 30px;
        }

        .contact-number {
          display: inline-flex;
          color: #2dca8c;
          border-radius: 10px;
          padding: 5px 15px;
          font-size: 18px;
          font-weight: 600;
          line-height: 36px;
          height: 36px;
          margin: 0 12px;
          background-color: #ffffff;

          & .icon {
            top: 4px;
            margin-right: 5px;
            height: 28px;

            &.logo {
              margin: 0;
            }
          }
        }
      }

      & .poster-details {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 90px;

        & > .description {
          display: inline-flex;
          flex-direction: column;

          & h1 {
            font-weight: bold;
            font-size: 60px;
            line-height: 100px;
            margin: 0;
            margin-bottom: 20px;
          }

          p {
            width: 60%;
            font-weight: 500;
            font-size: 22px;
            line-height: 170%;
          }

          .order-button {
            width: 250px;
            margin-top: 40px;
            display: inline-flex;
            color: #2dca8c;
            border-radius: 10px;
            padding: 5px 15px;
            font-size: 18px;
            font-weight: 600;
            line-height: 36px;
            justify-content: center;
            background-color: #ffffff;
          }
        }

        & > .order-link {
          display: inline-flex;
          flex-direction: column;
          width: 300px;
        }
      }

      & .scroll-down {
        display: inline-flex;
        font-weight: 500;
        font-size: 20px;
        line-height: 170%;
        color: #ffffff;
        margin-bottom: 50px;

        & .icon {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    &.features-details {
      flex-direction: column;

      & .features-list {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 100px;
        margin-bottom: 30px;

        & > .feature-item {
          display: inline-flex;
          flex-direction: column;
          padding: 30px;
          align-items: center;
          width: 25%;
          height: 280px;
          background: #ffffff;
          border-radius: 15px;
          margin-right: 7.5px;

          & .icon-wrap {
            width: 116px;
            height: 116px;
            background: #eafaf3;
            border-radius: 50%;
            justify-content: center;
            display: inline-flex;

            & .icon {
              width: 60px;
            }
          }

          p {
            margin-top: 25px;
            font-weight: 600;
            font-size: 20px;
            line-height: 35px;
            text-align: center;
            color: #333333;
          }
        }
      }

      & .prices-list {
        display: inline-flex;
        flex-direction: column;
        margin: 60px 15%;
        width: 80%;

        & > .price-item {
          position: relative;
          display: inline-flex;
          flex-direction: row;
          height: 85px;
          background: #ffffff;
          border-radius: 15px;
          margin-bottom: 40px;

          &.heading {
            background-color: transparent;
            height: 50px;

            & > .value {
              font-weight: bold;
              color: #333333;
              padding: 15px;
              font-size: 24px;
            }
          }

          & > .promotion-label {
            position: absolute;
            background-color: #2dca8c;
            text-transform: uppercase;
            color: #fcfcfd;
            padding: 6px 18px;
            font-size: 12px;
            line-height: 12px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            top: -12px;
          }

          & > .value {
            display: inline-flex;
            align-items: center;
            align-self: center;
            flex-direction: column;
            width: 20%;
            padding: 25px;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #858a8f;

            &.label {
              width: 40%;
              text-align: left;
              font-weight: 600;
              color: #333333;
              flex-direction: row;
            }

            & p {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              color: #de0000;
              margin: 0;

              &.old-price {
                font-size: 16px;
                color: #858a8f;
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }

    &.devis-generator {
      flex-direction: row;
      justify-content: center;

      & img {
        aspect-ratio: 1;
        width: 40%;
        margin-right: 10%;
      }

      & .home_order_form {
        width: 435px;
        position: relative;
        justify-content: center;
        margin-top: 45px;
        background-color: #fcfcfd;
        padding: 32px;

        & .formHeader {
          & h1 {
            margin-left: 5px;
            font-size: 42px;
            align-self: flex-start;
          }

          & span {
            color: #858a8f;
          }
        }

        & span {
          margin: 5px;
        }

        & .container {
          background-color: #fcfcfd;
        }

        & > .formFooter {
          margin: 10px 0;
          width: 100%;
          padding: 0;

          & .button {
            background: #2dca8c;
            border-radius: 10px;
            padding: 5px 25px;
            margin: 5px;
            height: 65px;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;

            &.cancel {
              background-color: transparent;
              border: 2px solid #f5f6fa;
              box-sizing: border-box;
              box-shadow: 0px 54px 50px rgba(107, 107, 107, 0.0705882);
              border-radius: 15px;
              color: #333333;
            }
          }

          & > .submitOrderNotes {
            padding: 10px 5px;
            font-size: 13px;
            color: #666;
            line-height: 20px;
          }
        }

        & .order_form_input,
        .calendarInput {
          border: 2px solid #f5f6fa;
          display: flex;
          width: calc(100% - 10px);
          max-width: 500px;
          margin-bottom: 10px;
          height: 65px;
          border-radius: 10px;
          padding: 0 25px;
          font-size: 20px;
          line-height: 24px;

          &.calendar {
            padding: 0;
          }
        }

        & .calendarInput {
          width: 100%;
          margin: 0;
        }

        .tick-icon {
          width: 18px;
          height: 18px;
        }

        .progressStep {
          text-align: left;

          &.totalsFooter {
            border-top: 1px solid #999999;
            margin-top: 10px;
            font-size: 18px;
            font-weight: bold;
            padding: 10px 2px 20px 2px;
            justify-content: space-between;
          }

          & > .description {
            width: calc(90% - 10px);
            line-height: 18px;
          }
        }
      }
    }

    &.photos-gallery {
      & > .gallery {
        padding: 45px 0;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(8, 5vw);
        grid-gap: 1.5rem;

        & figure {
          margin: 0;
        }

        .gallery__item--1 {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;
        }

        .gallery__item--2 {
          grid-column-start: 3;
          grid-column-end: 5;
          grid-row-start: 1;
          grid-row-end: 3;
        }

        .gallery__item--3 {
          grid-column-start: 5;
          grid-column-end: 9;
          grid-row-start: 1;
          grid-row-end: 6;
        }

        .gallery__item--4 {
          grid-column-start: 1;
          grid-column-end: 5;
          grid-row-start: 3;
          grid-row-end: 6;
        }

        .gallery__item--5 {
          grid-column-start: 1;
          grid-column-end: 5;
          grid-row-start: 6;
          grid-row-end: 9;
        }

        .gallery__item--6 {
          grid-column-start: 5;
          grid-column-end: 9;
          grid-row-start: 6;
          grid-row-end: 9;
        }

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }
    }

    &.services-details {
      padding: 50px 10%;
      width: 80%;

      & article {
        display: inline-flex;
        flex-direction: row;
        margin: 30px 0;
        border: 2px solid #f5f6fa;
        box-sizing: border-box;
        box-shadow: 0px 54px 50px rgba(107, 107, 107, 0.0705882);
        border-radius: 15px;

        & img {
          width: 490px;
          object-fit: cover;
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;

          &.right-aligned {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }

        & .details {
          width: calc(75% - 25px);
          text-align: left;
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: 25px;

          & h2 {
            width: 80%;
            font-weight: bold;
            font-size: 48px;
            line-height: 58px;
            color: #333333;
            margin: 15px 0;
          }

          & p {
            width: 80%;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 36px;
            color: #858a8f;
            margin: 20px 0;
          }

          & button {
            margin-top: 30px;
            color: #2dca8c;
            border-radius: 10px;
            padding: 15px 20px;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            border: 2px solid #f5f6fa;
            box-sizing: border-box;
            border-radius: 10px;
            background-color: #ffffff;
            display: inline-flex;
            width: 230px;
          }
        }
      }
    }

    &.feedbacks-list > .section-wrap {
      h3 {
        width: 100%;
        text-align: left;
      }

      & > .section-content {
        display: flex;
        width: 100%;
        flex-direction: column;

        & > .section-heading {
          display: inline-flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          & > .tabs {
            display: inline-flex;
            flex-direction: row;
            padding: 7px;
            height: 35px;
            border-bottom: 1px solid #2dca8c;

            & span {
              font-weight: 600;
              font-size: 20px;
              line-height: 35px;
              text-align: center;
              color: #333333;
            }
          }

          & > .create-feedback-button {
            width: 316px;
            height: 66px;
            background: #2dca8c;
            border-radius: 10px;
            padding: 20px 70px;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #ffffff;
          }
        }

        & > .section-body {
          margin-top: 20px;

          & .testimonial {
            width: calc(100% - 42px);
            text-align: left;
            padding: 20px;
            background-color: #ffffff;
            border-radius: 15px;

            & .heading {
              width: 100%;
              display: inline-flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              & > .autor {
                display: inline-flex;
                align-content: center;

                & img {
                  height: 28px;
                  width: 28px;
                  margin-right: 10px;
                }

                & h4 {
                  font-weight: bold;
                  font-size: 28px;
                  line-height: 28px;
                  margin: 0;
                  color: #333333;
                }
              }
            }

            & .created-date {
              font-size: 20px;
              line-height: 22px;
              color: #333333;
              margin: 14px 0;
            }

            & p {
              font-weight: normal;
              font-size: 20px;
              line-height: 36px;
              color: #858a8f;
            }

            &:nth-child(odd) {
              margin-left: 0;
            }

            &:nth-child(even) {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.contacts-list > .section-wrap {
      padding: 120px;
      flex-direction: row;
      justify-content: center;

      & .contact-card {
        width: 320px;
        height: 400px;
        text-align: left;
        background: #ffffff;
        border-radius: 15px;
        padding: 30px;
        margin: 15px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        & .icon-wrap {
          width: 75px;
          height: 75px;
          background: #eafaf3;
          border-radius: 50%;
          margin-bottom: 20px;
          justify-content: center;
          display: inline-flex;

          & .icon {
            height: 30px;
            top: 23px;
          }
        }

        h4 {
          margin: 10px 0;
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
          color: #333333;
        }

        p {
          margin-bottom: 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #858a8f;
        }

        a {
          color: #2dca8c;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  footer {
    width: 100%;
    display: inline-flex;
    align-items: center;

    & .section-wrap {
      width: 1200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    & a {
      font-weight: normal;
      font-size: 14px;
      line-height: 100%;
      text-transform: uppercase;
      color: #a7a7a7;
      margin-right: 10px;
    }
  }
}

.submit-feedback-form {
  & textarea {
    resize: none;
    height: 200px;
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px;
    font-size: 14px;
    line-height: 20px;
    color: #858a8f;
  }

  & .button {
    background: #2dca8c;
    border-radius: 10px;
    padding: 5px 15px;
    margin: 5px 0;
    height: 35px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &.cancel {
      background-color: transparent;
      border: 2px solid #f5f6fa;
      box-sizing: border-box;
      box-shadow: 0px 54px 50px rgba(107, 107, 107, 0.0705882);
      border-radius: 15px;
      color: #333333;
    }
  }
}

.feedback-modal {
  flex-direction: column;
  display: flex;
  padding: 30px;
  text-align: center;
  align-items: center;
}

.stars-rate {
  display: inline-flex;
  flex-direction: row;

  & > .icon {
    display: inline-flex;
    width: 30px;
    height: 30px;
    margin: 5px;
  }
}

.submit-feedback-form {
  margin-top: 20px;
}
