@import '../../../style/variables.scss';

.popupModal {
  position: fixed;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  &.mobile {
    & > .closer {
      right: 0;
    }

    & > .content {
      width: calc(100% - 48px);
      margin: 80px 12px;
    }
  }

  & > .closer {
    position: fixed;
    width: 100%;
    height: calc(100% + 160px);
    background-color: #000;
    opacity: 0.7;
    z-index: 99;
    right: 15px;
  }

  & > .content {
    position: relative;
    display: flex;
    justify-content: center;
    width: 365px;
    min-height: 600px;
    margin: 80px calc(50% - 150px);
    padding: 10px;
    background-color: $primary-white;
    z-index: 100;
  }
}
