@import '../../../style/variables.scss';

.lds-dual-ring {
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 23px;
  height: 23px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid $brand-primary;
  border-color: $brand-primary transparent $brand-primary transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
