$font-size-base: 16px;
$font-family-base: 'Open Sans', sans-serif;
$text-color: #464555;
$link-color: #337ab7;

$brand-primary: #4a4a4a !default;
$brand-secondary: #53686a !important;

$brand-succes: #69af67 !default;
$brand-danger: #ff6c47 !default;

$body-bg: #e8e8e8;
$primary-white: #fff;
