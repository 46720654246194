@import './variables.scss';

.inputForm {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  & > .formInput {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    position: relative;
    width: 325px;
    padding: 5px;

    & label,
    .label {
      box-sizing: border-box;
      display: inline-flex;
      color: $text-color;
      width: 100%;
      padding: 0 2px;
      line-height: 30px;
      font-weight: 600;
    }

    &.checbox {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.formFooter {
  & .link {
    width: fit-content;
    align-self: flex-end;
  }

  & > .submitStatus {
    font-size: 14px;

    &.error {
      color: red;
    }

    &.warn {
      color: orange;
    }

    &.success {
      color: green;
    }
  }

  display: inline-flex;
  justify-content: flex-end;
  flex-direction: column;
  align-self: flex-start;
  padding: 5px;
  width: 325px;
}

.inputContainer,
input,
select {
  box-sizing: border-box;
  display: inline-flex;
  padding: 5px;
  margin: 2px;
  border-radius: 3px;
  border: 1px solid $text-color;
  font-size: $font-size-base;
  font-family: $font-family-base;

  &:focus {
    box-shadow: 0 0 2px 1px $link-color !important;
  }
}

.mobile {
  & input,
  select {
    height: 31px;
    width: calc(100% - 4px);
  }
}
