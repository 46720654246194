.legacyContent {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 70%;

  & p {
    margin: 20px 0;
  }

  & > .content {
    display: inline-flex;
    flex-direction: column;
    width: 80%;
    padding: 10px;
  }

  & > .navigation {
    display: inline-flex;
    flex-direction: column;
    width: 20%;
    padding: 10px;
  }
}
