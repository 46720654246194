@import url('https://fonts.googleapis.com/css?family=Inter');

.landing-page-mobile {
  font-family: Inter;
  font-style: normal;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #333333;

  & > section {
    background-color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    & .section-wrap {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      min-height: 150px;
      padding: 20px;
      width: calc(100% - 40px);
      margin: 0;

      & img.icon {
        user-select: none;
        position: relative;
      }

      & > h3 {
        font-weight: bold;
        font-size: 46px;
        line-height: 56px;
        color: #333333;
      }

      & > span {
        font-size: 18px;
        line-height: 36px;
        color: #858a8f;
        font-weight: 400;
      }
    }

    &.primary {
      background-color: #eafaf3;
    }

    &.partners-list {
      & > .section-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & > .partner-card {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          height: 100px;
          width: 100%;
          border: 1px solid #e5e5e5;
          border-radius: 16px;

          & img {
            height: 50px;
          }
        }
      }
    }

    &.intro-poster {
      background: linear-gradient(0deg, rgba(5, 5, 5, 0.8), rgba(5, 5, 5, 0.8)),
        url('../../style/assets/intro_bg.jpg');
      background-size: cover;
      background-position: center;

      & > .section-wrap {
        color: #ffffff;
        text-align: left;
        align-items: flex-start;
      }

      & header {
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 20px;
        margin-bottom: 30px;
        text-align: center;

        .pull-left {
          display: flex;
        }

        .logo {
          display: inline-flex;
          height: 40px;
        }

        .numbers-wrap {
          display: inline-flex;
          flex-direction: column;
        }

        .contact-number {
          display: inline-flex;
          color: #2dca8c;
          border-radius: 10px;
          padding: 5px 10px;
          font-weight: 600;
          font-size: 16px;
          line-height: 32px;
          margin-bottom: 16px;
          background-color: #ffffff;

          & .icon {
            top: 4px;
            margin-right: 5px;
            height: 28px;
          }
        }

        .media-links {
          display: flex;
          flex-direction: row;

          & .contact-number {
            flex: 1;
            justify-content: center;

            & .icon {
              margin: 0;
              top: 0;
            }

            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }

      & .poster-details {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 60px;

        & > .description {
          display: inline-flex;
          flex-direction: column;

          .order-button {
            background: #ffffff;
            border-radius: 10px;
            line-height: 24px;
            font-weight: 600;
            font-size: 18px;
            padding: 17px;
            margin-top: 50px;
            color: #2dca8c;
          }

          & h1 {
            font-weight: bold;
            font-size: 40px;
            line-height: 50px;
            align-items: center;
            color: #ffffff;
          }

          p {
            font-weight: 500;
            font-size: 18px;
            line-height: 170%;
            color: #ffffff;
          }
        }

        & > .order-link {
          display: inline-flex;
          flex-direction: column;
        }
      }

      & .scroll-down {
        display: inline-flex;
        font-weight: 500;
        font-size: 20px;
        line-height: 170%;
        color: #ffffff;
        margin-bottom: 40px;

        & .icon {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    &.features-details {
      flex-direction: column;

      & .features-list {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 30px;

        & > .feature-item {
          display: inline-flex;
          flex-direction: column;
          padding: 30px;
          margin: 20px;
          align-items: center;
          background: #ffffff;
          border-radius: 15px;

          & .icon-wrap {
            width: 116px;
            height: 116px;
            background: #eafaf3;
            border-radius: 50%;
            justify-content: center;
            display: inline-flex;

            & .icon {
              width: 60px;
            }
          }

          p {
            margin-top: 25px;
            font-weight: 600;
            font-size: 18px;
            line-height: 35px;
            text-align: center;
            color: #333333;
          }
        }
      }

      & .prices-list {
        width: 100%;
        margin-top: 30px;
        display: inline-flex;
        flex-direction: column;

        & > .price-item {
          position: relative;
          display: inline-flex;
          flex-direction: column;
          height: 105px;
          justify-content: center;
          background: #ffffff;
          border-radius: 15px;
          margin-bottom: 40px;

          &.heading {
            background-color: transparent;
            height: 40px;

            & .values > .value {
              font-weight: bold;
              color: #333333;
              padding: 10px;
            }
          }

          & > .promotion-label {
            position: absolute;
            background-color: #2dca8c;
            text-transform: uppercase;
            color: #fcfcfd;
            padding: 6px 18px;
            font-size: 12px;
            line-height: 12px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            top: -12px;
            width: 100%;
            box-sizing: border-box;
          }

          & > .values {
            display: inline-flex;
            flex-direction: row;

            & > .value {
              display: inline-flex;
              justify-content: left;
              flex-direction: column;
              padding: 5px;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #858a8f;
              flex: 1;
            }
          }

          & > .label {
            padding: 5px 10px;
            text-align: left;
            font-weight: 600;
            color: #333333;
          }

          & p {
            color: #de0000;
            margin: 0;

            &.old-price {
              font-size: 12px;
              color: #858a8f;
              text-decoration: line-through;
            }
          }
        }
      }
    }

    &.devis-generator {
      & .home_order_form {
        position: relative;
        justify-content: center;
        margin-top: 30px;
        background-color: #fcfcfd;
        padding: 32px;

        & .container {
          background-color: #fcfcfd;
        }

        & > .formFooter {
          margin: 10px 0;
          width: 100%;
          padding: 0;

          & .button {
            background: #2dca8c;
            border-radius: 10px;
            padding: 5px 25px;
            margin: 5px;
            height: 65px;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;

            &.cancel {
              background-color: transparent;
              border: 2px solid #f5f6fa;
              box-sizing: border-box;
              box-shadow: 0px 54px 50px rgba(107, 107, 107, 0.0705882);
              border-radius: 15px;
              color: #333333;
            }
          }

          & > .submitOrderNotes {
            padding: 10px 5px;
            font-size: 13px;
            color: #666;
            line-height: 20px;
          }
        }

        & .order_form_input,
        .calendarInput {
          border: 2px solid #f5f6fa;
          display: flex;
          width: calc(100% - 10px);
          max-width: 500px;
          margin-bottom: 10px;
          height: 65px;
          border-radius: 10px;
          padding: 0 25px;
          font-size: 20px;
          line-height: 24px;

          &.calendar {
            padding: 0;
          }
        }

        & .calendarInput {
          width: 100%;
          margin: 0;
        }

        .tick-icon {
          width: 18px;
          height: 18px;
        }

        .progressStep {
          text-align: left;

          &.totalsFooter {
            border-top: 1px solid #999999;
            margin-top: 10px;
            font-size: 18px;
            font-weight: bold;
            padding: 10px 2px 20px 2px;
            justify-content: space-between;
          }

          & > .description {
            width: calc(90% - 10px);
            line-height: 18px;
          }
        }
      }
    }

    &.services-details {
      & article {
        display: inline-flex;
        flex-direction: column;
        border: 2px solid #f5f6fa;
        box-sizing: border-box;
        box-shadow: 0px 54px 50px rgba(107, 107, 107, 0.0705882);
        padding-bottom: 50px;
        border-radius: 15px;
        margin: 33px 0;

        & img {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;

          &.right-aligned {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }

        & .details {
          padding: 16px;
          text-align: left;
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;

          & h2 {
            font-weight: bold;
            font-size: 48px;
            line-height: 58px;
            color: #333333;
            margin: 15px 0;
          }

          & p {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 36px;
            color: #858a8f;
            margin-bottom: 35px;
          }

          & button {
            color: #2dca8c;
            border: 2px solid #f5f6fa;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 15px 20px;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            background-color: #ffffff;
            display: inline-flex;
            width: 230px;
          }
        }
      }
    }

    &.feedbacks-list > .section-wrap {
      & > .section-content {
        display: flex;
        width: 100%;
        flex-direction: column;

        & > .section-heading {
          display: inline-flex;
          flex-direction: row;
          justify-content: center;

          & > .create-feedback-button {
            width: 316px;
            height: 66px;
            background: #2dca8c;
            border-radius: 10px;
            padding: 20px 70px;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #ffffff;
          }
        }

        & > .section-body {
          margin-top: 20px;

          & .testimonial {
            width: calc(100% - 42px);
            text-align: left;
            padding: 20px;
            background-color: #ffffff;
            border-radius: 15px;

            & .heading {
              width: 100%;
              display: inline-flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              & > .autor {
                display: inline-flex;
                align-content: center;

                & img {
                  height: 28px;
                  width: 28px;
                  margin-right: 10px;
                }

                & h4 {
                  font-weight: bold;
                  font-size: 24px;
                  line-height: 24px;
                  margin: 0;
                  color: #333333;
                }
              }
            }

            & .created-date {
              font-size: 16px;
              line-height: 18px;
              color: #333333;
              margin: 14px 0;
            }

            & p {
              font-weight: normal;
              font-size: 16px;
              line-height: 30px;
              color: #858a8f;
            }

            &:nth-child(odd) {
              margin-left: 0;
            }

            &:nth-child(even) {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.contacts-list > .section-wrap {
      flex-direction: column;

      & .contact-card {
        width: 100%;
        text-align: left;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 15px;
        padding: 28px 16px;
        margin: 10px 0;

        & .icon-wrap {
          width: 75px;
          height: 75px;
          background: #eafaf3;
          border-radius: 50%;
          margin-bottom: 20px;
          justify-content: center;
          display: inline-flex;

          & .icon {
            height: 30px;
            top: 23px;
          }
        }

        h4 {
          margin: 10px 0;
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
          color: #333333;
        }

        p {
          margin-bottom: 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: #858a8f;
        }

        a {
          color: #2dca8c;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    &.photos-gallery {
      & .gallery {
        padding: 20px 0;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(8, 5vw);
        grid-gap: 1rem;

        & figure {
          margin: 0;
        }

        .gallery__item--1 {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;
        }

        .gallery__item--2 {
          grid-column-start: 3;
          grid-column-end: 5;
          grid-row-start: 1;
          grid-row-end: 3;
        }

        .gallery__item--3 {
          grid-column-start: 5;
          grid-column-end: 9;
          grid-row-start: 1;
          grid-row-end: 6;
        }

        .gallery__item--4 {
          grid-column-start: 1;
          grid-column-end: 5;
          grid-row-start: 3;
          grid-row-end: 6;
        }

        .gallery__item--5 {
          grid-column-start: 1;
          grid-column-end: 5;
          grid-row-start: 6;
          grid-row-end: 9;
        }

        .gallery__item--6 {
          grid-column-start: 5;
          grid-column-end: 9;
          grid-row-start: 6;
          grid-row-end: 9;
        }

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }
    }
  }

  footer {
    padding: 20px;
    display: inline-flex;
    align-items: center;

    & .copyright {
      width: 25%;
    }

    & .section-wrap {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    & a {
      font-weight: normal;
      font-size: 10px;
      line-height: 100%;
      text-transform: uppercase;
      color: #a7a7a7;
      margin-right: 10px;
    }
  }
}

.stars-rate-mobile {
  display: inline-flex;
  flex-direction: row;

  & > .icon {
    display: inline-flex;
    width: 22px;
    height: 22px;
    margin: 2px;
  }
}
